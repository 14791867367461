import { Link } from "gatsby";
import React from "react";

import Logo from "../../images/logo.svg";

const Header = () => {
  return (
    <header>
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="flex w-full items-center justify-between py-6">
          <div className="flex items-center">
            <Link to="/">
              <span className="sr-only">Using Tailwind</span>
              <Logo style={{ maxWidth: 150, height: "auto" }} />
            </Link>
          </div>
          <div className="ml-10 space-x-8">
            <Link
              to="/resources"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Resources
            </Link>
            <Link
              to="/submit"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Submit a site
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
